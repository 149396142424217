/* SchoolRegistration.css */
@font-face {
  font-family: "Nanum Gothic";
  src: url("../fonts/NanumGothic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.container-full-height {
  min-height: 100vh; /* Viewport Height */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.account-input {
  margin-bottom: 0.5rem;
}

.account-part {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}
.account-text {
  color: #000;
  font-size: 16px;
  font-weight: Bold;
  margin-left: 0rem;
  margin-right: 0rem;
  margin-bottom: 0.5rem;
}

.row-align {
  display: flex;
  align-items: center;
  gap: 0.1rem; /* 원하는 간격 설정 */
}

.card-text p {
  margin-bottom: 0.5rem;
}

.card-header {
  background-color: #007bff;
  color: white;
  text-align: center;
}

.card {
  border-radius: 10px;
  overflow: hidden;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-container .btn {
  margin-right: 10px;
}

.table td,
.table th {
  font-size: 14px;
  padding: 8px;
}

.table td {
  vertical-align: middle;
}

.table th {
  width: 150px;
  background-color: #f8f9fa;
}

/* SchoolRegistration.css */
label {
  color: #000; /* 검정색 */
  font-weight: bold; /* 굵은 글씨 */
  font-size: 0.6em; /* 약간 큰 글씨 */
  margin-bottom: 0.5rem; /* 아래 여백 */
  display: block; /* 블록 요소로 변경 */
}
