.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 2rem;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

.form-control {
  font-size: 16px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  gap: 10px;
}

.button {
  width: 48%;
  margin: 0 1%;
}

.card {
  background-color: "#b2dafc";
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
}

.card-title {
  font-size: 24px;
  margin-bottom: 16px;
}

.card-body {
  font-size: 14px;
}

.card-text {
  margin-bottom: 12px;
}

/* SchoolList.css */

.school-item {
  background-color: white;
  color: black;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.school-info {
  margin-bottom: 10px;
  font-size: 16px;
}

.btn {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

.btn-primary:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.p {
  font-size: 14px;
}
