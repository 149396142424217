@font-face {
  font-family: "Nanum Gothic";
  src: url("./fonts/NanumGothic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  min-height: 100vh;
  margin: 0;
}

body {
  font-family: "Nanum Gothic", sans-serif;
  margin: 0; /* Ensure no default margin is applied */
}

.main {
  flex: 1;
  overflow-y: auto;
  background-color: #fff;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the top */
  align-items: center;
}

.App-header {
  background-color: #b2d4ff;
  min-height: 90vh; /* Adjust to give space for the footer */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%; /* Ensure header takes full width */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
  margin-bottom: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-text-logo {
  width: 400px;
  margin-top: 20px;
}

button {
  width: 200px;
}

.App-footer {
  background-color: #1f1f1f;
  padding: 20px;
  text-align: center;
  width: 100%; /* Ensure footer takes full width */
  position: relative; /* Changed from absolute to relative */
  bottom: 0;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.Footer-logo {
  height: 40px;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
}

.App-footer p {
  display: inline;
  margin: 0;
  font-size: 1rem;
  vertical-align: middle;
}
